import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Import PrimeNG modules
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/primeng';

import { LayoutModule } from './layouts/layout.module';
import { HealthModule } from './pages/health/health.module';

const routes: Routes = [
  {
    path: 'health',
    loadChildren: './pages/health/health.module#HealthModule'
  },
  {
    path: '',
    loadChildren: './layouts/layout.module#LayoutModule',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' }),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    MultiSelectModule,
    CalendarModule,
    DropdownModule,
    LayoutModule,
    HealthModule
  ],
  providers: [],
  exports: [RouterModule]
})

export class AppRoutingModule { }
