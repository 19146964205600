import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { HomeComponent } from '../pages/home/home.component';

import { CreateTestModule } from '../pages/createtest/create-test.module';
import { TestResultModule } from '../pages/testresult/testresult.module';
import { AdminModule } from '../pages/admin/admin.module';
import { LoginModule } from '../pages/login/login.module';

const layoutRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'createtest',
                loadChildren: '../pages/createtest/create-test.module#CreateTestModule'
            },
            {
                path: 'testresult',
                loadChildren: '../pages/testresult/testresult.module#TestResultModule'
            },
            {
                path: 'testresult/:id',
                loadChildren: '../pages/testresult/testdetails/testdetails.module#TestDetailsModule'
            },
            {
                path: 'admin',
                loadChildren: '../pages/admin/admin.module#AdminModule'
            },
            {
                path: 'login',
                loadChildren: '../pages/login/login.module#LoginModule'
            }
        ]
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(layoutRoutes)],
    exports: [RouterModule],
})

export class LayoutRoutingModule {
}
