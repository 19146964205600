import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HealthComponent } from './health.component';

const healthRoutes: Routes = [
    { path: '', component: HealthComponent },
];

@NgModule({
    imports: [RouterModule.forChild(healthRoutes)],
    exports: [RouterModule],
})

export class HealthRoutingModule {
}
