import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent implements OnInit {
  data: any;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.pageLoad();
  }

  pageLoad() {
    this.commonService.healthResult().subscribe(value => {
      this.data = value;
    });
  }
}
