import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  public currentToken = '';
  constructor(public routers: Router, private cookieService: CookieService) {
    this.currentToken = this.currentToken;
  }

  setAuthToken(token: string) {
    // sessionStorage.setItem('JSESSIONID', token);
    this.cookieService.set('JSESSIONID', token);
  }

  getAuthToken() {
    // this.currentToken = sessionStorage.getItem('JSESSIONID');
    this.currentToken = this.cookieService.get('JSESSIONID');
    return this.currentToken;
  }

  isLoggednIn() {
    // this.currentToken = sessionStorage.getItem('JSESSIONID');
    this.currentToken = this.cookieService.get('JSESSIONID');
    return this.currentToken;
  }

  isLoggedCheck() {
    // if (sessionStorage.getItem('JSESSIONID') === null) {
    if (this.cookieService.get('JSESSIONID') === null || this.cookieService.get('JSESSIONID') === '') {
      return false;
    } else {
      return true;
    }
  }

  logout() {
    // sessionStorage.removeItem('JSESSIONID');
    document.cookie = 'JSESSIONID=; Domain=' + environment.domain + '; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // this.cookieService.delete('JSESSIONID');
    this.routers.navigate(['/' + 'login']);
  }
}
