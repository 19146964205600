import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Import PrimeNG modules
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/primeng';

import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { LayoutComponent } from './layout.component';
import { HomeComponent } from '../pages/home/home.component';

import { LayoutRoutingModule } from './layout.routing';
@NgModule({
  declarations: [
    AppHeaderComponent,
    AppFooterComponent,
    LayoutComponent,
    HomeComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    MultiSelectModule,
    CalendarModule,
    DropdownModule,
    LayoutRoutingModule
  ],
  providers: [],
  exports: [RouterModule]
})

export class LayoutModule { }
