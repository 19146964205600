
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private authService: AuthService) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
        | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        // const token: string = this.authService.getAuthToken();

        // if (token) {
        //     req = this.addToken(req, this.authService.getAuthToken());
        // }

        // if (!req.headers.has('Content-Type')) {
        //     req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        // }

        // req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

        req = req.clone({ withCredentials: true });

        return next.handle(req)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // console.log('event--->>>', event);
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error instanceof HttpErrorResponse) {
                        const errMsg = (error as HttpErrorResponse).error;
                        const errStatus = (error as HttpErrorResponse).status;
                        if (errStatus === 400 && errMsg.error === 'token_invalid') {
                            this.authService.logout();
                        }

                        switch ((error as HttpErrorResponse).status) {
                            case 400:
                            // return this.handle400Error(error);
                            case 401:
                            // return this.handle401Error(req, next);
                        }

                        return observableThrowError(error);
                    } else {
                        return observableThrowError(error);
                    }
                })
            );
    }

}
