import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Import PrimeNG modules
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/primeng';

import { CookieService } from 'ngx-cookie-service';

import { RequestInterceptorService } from '../../_services/request-interceptor.service';
import { CommonService } from '../../_services/common.service';
import { AuthGuard } from '../../auth.guard';
import { AuthService } from '../../_services/auth.service';
import { AdminDialogComponent } from '../../common/admin-dialog/admin-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        MultiSelectModule,
        DropdownModule,
        CalendarModule
    ],
    declarations: [
        AdminDialogComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        AdminDialogComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true },
        CommonService,
        AuthGuard,
        AuthService,
        CookieService
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true },
                CommonService,
                AuthGuard,
                AuthService
            ]
        };
    }
}
