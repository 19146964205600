import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { CommonService } from './../../_services/common.service';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  activeMenu: string;

  constructor(private authService: AuthService, private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    // this.getActive();
  }

  getActive() {
    // console.log(this.router.url);
    // const currentURL = this.router.url;
    // switch (currentURL) {
    //   case '/':
    //     this.activeMenu = 'home';
    //     break;
    //   case '/login':
    //     this.activeMenu = 'login';
    //     break;
    // }
  }

  logout() {
    // this.commonService.Logout().subscribe(value => {
    //   const getCreateResponse = value;
    //   console.log(value);
    // }, err => {
    //   console.log(err);
    //   // this.messageService.add({ severity: 'error', summary: '', detail: err.message });
    // });
    this.authService.logout();
    this.router.navigate(['/' + 'login']);
  }

}
